import React from "react";
import moment from "moment";

// export const formatTimeforTableCell =  (timeValue:string) =>{
//     return moment(timeValue).format('DD MMM YYYY hh:mm a');
//    }

   export const formatTimeforTableCell = (timeValue:string) =>{
    return moment(timeValue).utcOffset(0,false).format('DD MMM YYYY HH:mm');
   }

   export const formatForDTPicker = (timeValue:string)=>{
      const startDate = moment(timeValue).utcOffset(0, false).format("YYYY-MM-DD" ); 
      const startTime = moment(timeValue).utcOffset(0, false).format("HH:mm");
      return(`${startDate}T${startTime}`);
   }

   export const formatTimeforTableCellLocal = (timeValue:string) =>{
      return moment(timeValue).utcOffset(0,true).format('DD MMM YYYY HH:mm');
     }

     export const formatGivenTimeForDTPicker =  (timeItem:string) =>{
      let timeItemValue = timeItem;
          if(timeItemValue === ''){
          timeItemValue = moment(Date.now()).utcOffset(0, true).toISOString();
      }
      const givenDate = moment(timeItemValue).utcOffset(0, false).format("YYYY-MM-DD" ); // .format("DD-MM-YYYY" );
      const givenTime = moment(timeItemValue).utcOffset(0, false).format("HH:mm");
      const formattedTime = `${givenDate}T${givenTime}`
      return formattedTime;
    }

   export const testFunction = () =>{
       
   }