import React, { useState, useRef, useEffect } from 'react';
import './App.css'
import { useDispatch } from 'react-redux';
import * as WatchUtils from '@arcgis/core/core/watchUtils';
import Map from '@arcgis/core/Map';
import ESRIMapView from '@arcgis/core/views/MapView';
import Point from '@arcgis/core/geometry/Point';
import Basemap from '@arcgis/core/Basemap';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Query from '@arcgis/core/tasks/support/Query';
import Graphic from '@arcgis/core/Graphic';
import QueryTask from '@arcgis/core/tasks/QueryTask';
import { burnActions } from 'store/burn-slice';
import moment from 'moment';
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import Sketch from "@arcgis/core/widgets/Sketch";
import Polygon from '@arcgis/core/geometry/Polygon';
import Search from "@arcgis/core/widgets/Search"; 
import Expand from "@arcgis/core/widgets/Expand"; 
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import LayerSearchSource from "@arcgis/core/widgets/Search/LayerSearchSource";
import configData from "config.json";
import AreasAndLengthsParameters from "@arcgis/core/tasks/support/AreasAndLengthsParameters";
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
// @ts-ignore
import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme } from '@material-ui/core';
import { MapCommand } from '../MapCommands';

import {formatGivenTimeForDTPicker as formatTime} from '../helper/helperService';

const { stringify } = require('wkt');

export const map = new Map({
  basemap: Basemap.fromId('streets'),
});
const graphicsLayer = new GraphicsLayer({
  id:"GraphicsLayer"
});
const burnGraphicsLayer = new GraphicsLayer({
  id:"BurnGraphicsLayer"
});
const burnGraphicsHighlightLayer = new GraphicsLayer({
  id:"BurnGraphicsHighlightLayer"
});
const burnPointGraphicsLayer = new GraphicsLayer({
  id:"burnPointGraphicsLayer"
});

const pointRenderer = new SimpleRenderer( {
  symbol: new SimpleMarkerSymbol({
    color: "#FF4000",
            outline: { 
              color: [255, 64, 0, 0.4], 
              width: 7
            },
    size: 16
  })
});

const polygonRender = new SimpleRenderer({
  symbol: new SimpleFillSymbol({
    // style : "forward-diagonal",
    color: 'orange',    
    outline: { 
      color: [255, 64, 0, 0.4], 
      width: 1
    },
  })
});;

const today = moment();
const todayDate = moment().format("YYYY-MM-DD");
const seventhDay = today.add(7,'day');
const seventhDayDate = seventhDay.format("YYYY-MM-DD");

 const defQuery = `ActivityStatus IN ('Scheduled','Implementation','Delivery') AND ( date '${todayDate}' <= ScheduledEndDate and date '${seventhDayDate}' >=ScheduledStartDate)`;
// const defQuery = `ActivityStatus IN ('Scheduled','Implementation') AND ( date '${todayDate}' <= ScheduledEndDate and date '${seventhDayDate}' >=ScheduledStartDate)`;
const guardianBurnPolyLayer = new FeatureLayer({
  url: process.env.REACT_APP_BURN_FEATURE_SERVICE_URL!,
  definitionExpression:defQuery,  // todo UNCOMMENT THIS LINE BN
  renderer:pointRenderer
}
);

map.add(guardianBurnPolyLayer);
// map.add(guardianBurnOverviewLayer);
map.add(graphicsLayer);
map.add(burnPointGraphicsLayer);
map.add(burnGraphicsLayer);
map.add(burnGraphicsHighlightLayer);

export const view = new ESRIMapView({
  center: new Point({
    latitude: -33,
    longitude: 151,
  }),
  zoom: 6,
  map,
});


const sketchVM = new SketchViewModel({
  // view,
  layer: graphicsLayer,
  updateOnGraphicClick: false,
  defaultUpdateOptions: {
    // set the default options for the update operations
    toggleToolOnClick: false 
  }
});

const sketchNewBurns = new SketchViewModel({
  layer: burnGraphicsLayer,
  // creationMode: "single",
   updateOnGraphicClick: false,
defaultCreateOptions:{
mode:"hybrid"
}
});


const searchConfig = configData.SEARCH;
const layerSearchSource = new LayerSearchSource(            
  {
    layer: guardianBurnPolyLayer,
    outFields:searchConfig.OUT_FIELDS, 
    displayField: searchConfig.DISPLAY_FIELD, 
    searchFields:searchConfig.SEARCH_FIELDS,
    exactMatch:  false,    
    maxResults:  searchConfig.MAX_RESULTS,
    maxSuggestions: searchConfig.MAX_SUGGESTIONS, 
    suggestionsEnabled:searchConfig.SUGGESTIONS_ENABLED, 
      /* eslint-disable */
    suggestionTemplate: searchConfig.SUGGESTION_TEMPLATE, 
    /* eslint-enable */   
    minSuggestCharacters: searchConfig.MIN_SUGGEST_CHARS,
    name: searchConfig.NAME, 
    placeholder: searchConfig.PLACE_HOLDER          
  }
);

const search = new Search({  
  locationEnabled:false,
  sources: [layerSearchSource],  
  includeDefaultSources:searchConfig.INCLUDE_DEFAULT_SOURCES,
});

// 
// https://community.esri.com/t5/arcgis-api-for-javascript-questions/remove-result-graphic-following-search-arcgis-api/td-p/2315
// search.on("select-result", function(event){
//   console.log("location was chosen ", event)
//   long = event.result.feature.geometry.centroid.longitude + 0.003
//   lat = event.result.feature.geometry.centroid.latitude
//   myView.center = [long, lat];
//   myView.zoom = 16
//   console.log("geometry: ", event.result.feature.geometry)
//   selectedParcel = event.result.feature
//   createHighlightedParcel();
// })‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍

const expand = new Expand({
  label: 'Search',
  content: search
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    dialogRoot: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formRoot: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
    snackbarRoot: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2)
      }
    }
  }),
);

interface Props {
  registerMapCommand?: (register: (command: MapCommand) => void) => void;
}

const MapView: React.FunctionComponent<Props> = ({ registerMapCommand }) => {
  const mapRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();
  const [ready, setReady] = useState(false);  
  const [open, setOpen] = useState(false);
  const [newBurnGraphic, setNewBurnGraphic] = useState<Graphic|null>(null);
  const [updatedBurnGraphic, setUpdatedBurnGraphic]=  useState<Graphic|null>(null);

  const formattedStartDate = formatTime('');
  // console.log(formattedStartDate);
  const formattedEndDate = formatTime('');
  // console.log(formattedEndDate);

  const BURN_DESC= 'Burn Description';
  const FUEL_TYPE = 'Forest';
  const FUEL_LOAD = '27.2';
  const BURN_TYPE = 'Hazard Reduction';
  const BURN_COVERAGE = '50';
  const BURN_AGENCY = 'Agency';

  const [burnDescription, setBurnDescription] = useState(BURN_DESC);
  const [fuelType, setFuelType] = useState(FUEL_TYPE);
  const [fuelLoad, setFuelLoad] = useState(FUEL_LOAD);
  const [burnType, setBurnType] = useState(BURN_TYPE);
  const [burnCoverage, setBurnCoverage] = useState(BURN_COVERAGE);
  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);
  const [errorTextStartDate, seterrorTextStartDate] = useState('');
  const [burnItemGraphic, setburnItemGraphic] = useState({});

  const formatGivenTimeForDatabase = (timeItem:string) => {
    const updatedStartDateTime = `${moment(timeItem, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString()?.split('.')[0]}Z`;
    return updatedStartDateTime;
  }
  const handleMapCommands = (command?: MapCommand) => {
    if (!command) return;
    command.apply(view, handleMapCommands);
  };
  type BurnListItem ={
    id: string,
    area: string,
    description: string,
    endDateTime: string,
    startDateTime: string
  }

const dispatch = useDispatch();


  useEffect(() => {
    if (ready && registerMapCommand) registerMapCommand(handleMapCommands);
  }, [ready]);

  const burnDescriptionChangeHandler =(event: React.ChangeEvent<HTMLInputElement>) => {
    setBurnDescription((event.target as HTMLInputElement).value);    
  }

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  const fuelTypeChangeHandler =(event: any) => {
    setFuelType((event.target as HTMLInputElement).value);   
    if(event.target.value.toLowerCase() === 'forest') {
      setFuelLoad("25.7");
    }
    else if(event.target.value.toLowerCase() === 'grass'){
      setFuelLoad("7.94");
    }
    else{
      setFuelLoad("25.7");
    }
  }

  const fuelLoadChangeHandler =(event: React.ChangeEvent<HTMLInputElement>) => {
    setFuelLoad((event.target as HTMLInputElement).value);    
  }
  const burnTypeChangeHandler =(event: any) => {
    setBurnType((event.target as HTMLInputElement).value);    
  }

  const burnCoverageChangeHandler =(event:any) => {
    setBurnCoverage((event.target as HTMLInputElement).value);    
  }

  const burnStartDateBlurHandler = (event:any) => {
    // const updatedStartDateTime = `${moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString()?.split('.')[0]}Z`;
   // console.log(event.target.value);
    // const formattedTime = formatTime(moment(event.target.value).utcOffset(0, true).toISOString());
    const updatedValue = moment(event.target.value, "YYYY-MM-DD HH:mm");
    if(!updatedValue.isAfter(moment(endDate))){
      seterrorTextStartDate('');
    }else{
      seterrorTextStartDate('Start date cannot be after end date!!');
    }
    
    setStartDate(event.target.value);   
}


const burnStartDateChangeHandler = (event:any) => {
  // const updatedStartDateTime = `${moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString()?.split('.')[0]}Z`;
 // console.log(event.target.value);
  // const formattedTime = formatTime(moment(event.target.value).utcOffset(0, true).toISOString());

  setStartDate(event.target.value);   
}

const burnEndDateBlurHandler = (event:any) => {
  // const updatedEndDateTime = `${moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString()?.split('.')[0]}Z`;
 
  // const formattedTime = formatTime(moment(event.target.value).utcOffset(0, true).toISOString());
  const updatedValue = moment(event.target.value, "YYYY-MM-DD HH:mm");
  if(!updatedValue.isBefore(moment(startDate))){
    seterrorTextStartDate('');
  }
  else{
    seterrorTextStartDate('Start date cannot be after end date!!');
  }
  // setEndDate(event.target.value);   
}


const burnEndDateChangeHandler = (event:any) => {
  // const updatedStartDateTime = `${moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString()?.split('.')[0]}Z`;
 // console.log(event.target.value);
  // const formattedTime = formatTime(moment(event.target.value).utcOffset(0, true).toISOString());
  setEndDate(event.target.value);   
}

const removeAllGraphics = ()=>{
  view.graphics.removeAll();
  graphicsLayer.removeAll();
  burnPointGraphicsLayer.removeAll();
  burnGraphicsHighlightLayer.removeAll();
  dispatch(burnActions.updateIsBurnSelected (false));

}

const removeBurnGraphics = () => {
  burnGraphicsHighlightLayer.graphics.removeAll();
  burnGraphicsLayer.removeAll();
}

const getTimeValues = (startDateISO:string,endDateISO:string,startDateNonISO:moment.Moment,endDateNonISO:moment.Moment,areaHa:string) => {
 
  const burnListArray : BurnListItem[] = [];
 try{
  let burnListItem = {
      id: "1",
      area: areaHa === ''? '650' : areaHa,
      description: "",
      // endDateTime: `${?.split('.')[0]}Z`,
      // startDateTime: `${endDateISO?.split('.')[0]}Z`
      endDateTime: `${endDateISO?.split('.')[0]}Z`,
      startDateTime: `${startDateISO?.split('.')[0]}Z`
    }
    const dayDifference = endDateNonISO.diff(startDateNonISO, 'days')
    const proposedNextDayStartTime = startDateNonISO.startOf('day').add(10,'hour')
    let nextDayStartTime =startDateNonISO.isAfter(proposedNextDayStartTime) ? startDateNonISO : proposedNextDayStartTime;
    const startDateNonISOCopy = startDateNonISO.clone();
    let nextDayEndTime=startDateNonISOCopy.add(1,'day').startOf('day').subtract(7,'hour');
    if (dayDifference <= 0){
      burnListArray.push(burnListItem);
    }
    else{
      let burnDayId = 1;
      const startDayStartTime = startDateNonISO;
      let nextDayEndTimeISOString ='';
      let nextDayStartTimeISOString = '';
      let exitLoop= false;
      for (let i = 0; i <= dayDifference; i += 1) {
        nextDayStartTimeISOString = nextDayStartTime.seconds(0).milliseconds(0).toISOString();
        nextDayEndTimeISOString = nextDayEndTime.seconds(0).milliseconds(0).toISOString();
        if(i === dayDifference){
          exitLoop = true;                  
          nextDayStartTimeISOString = nextDayStartTime.isAfter(endDateNonISO)?endDateNonISO.seconds(0).milliseconds(0).toISOString(): nextDayStartTimeISOString;
          nextDayEndTimeISOString = endDateNonISO.isBefore(nextDayEndTime)? endDateNonISO.seconds(0).milliseconds(0).toISOString() : nextDayEndTimeISOString;
        }

        burnListItem = {
          id:burnDayId.toString(),
          startDateTime: `${nextDayStartTimeISOString?.split('.')[0]}Z`,
          endDateTime:`${nextDayEndTimeISOString?.split('.')[0]}Z`,
          description:"",
          area: areaHa === ''? '650' : areaHa,

        }
          burnListArray.push(burnListItem);
        if (exitLoop) break;
        burnDayId +=  1;
        const startTimeCopy = nextDayStartTime;
        const endTimeCopy = nextDayStartTime.clone(); // will be mutated by endof
        nextDayStartTime =startTimeCopy.add(1,'day').utcOffset(0,true).startOf('day').add(10,'hour');
        nextDayEndTime=endTimeCopy.add(2,'day').startOf('day').utcOffset(0,true).subtract(7,'hour');
      }
    }
    return burnListArray;
  }
  catch(ex){
    console.log("Error occured while getting burn days ")
    return burnListArray;
  }
}

const getTimeValuesPolygonGraphic = (startDateVal:string,endDateVal:string,areaHa:string) => {
 
  const burnListArray : BurnListItem[] = [];
 try{
  const  endDateTime = moment(endDateVal);
  const  startDateTime = moment(startDateVal);
 
  // spl case
  const startDateCopy = startDateTime.clone();
  const startDateCopy2 = startDateTime.clone();
    


  let burnListItem = {
    id: "1",
    area: areaHa === ''? '650' : areaHa,
    description: "",
  
    endDateTime: `${endDateVal?.split('.')[0]}Z` ,
    startDateTime: `${startDateVal?.split('.')[0]}Z`
  }

    const dayDifference = endDateTime.diff(startDateCopy2.startOf('day'), 'days'); // each date will be new burn day
    const proposedNextDayStartTime = startDateCopy.startOf('day').add(10,'hour'); // start date 10 am
    let nextDayStartTime =proposedNextDayStartTime.isBefore(startDateTime) ?startDateTime : proposedNextDayStartTime  ; // WRONG
    const startDateNonISOCopy = startDateTime.clone();
    let nextDayEndTime=startDateNonISOCopy.add(1,'day').startOf('day').subtract(7,'hour'); // start date 7 pm
    nextDayEndTime = nextDayEndTime.isBefore(startDateTime) ?  startDateTime :nextDayEndTime ; 
    if (dayDifference <= 0){ // same day
        const endDateCopy2 = endDateTime.clone();
        const startDateCopy1 = startDateTime.clone();
        const endTime5PM = endDateCopy2.add(1,'day').startOf('day').subtract(7,'hour');
        const startTime10AM = startDateCopy1.startOf('day').add(10,'hour');

        let  burnStartDate  = startDateTime.isBefore(startTime10AM)?startTime10AM:startDateTime; 
        let burnEndDate =endDateTime.isAfter(endTime5PM)?endTime5PM:endDateTime ;

        if(startDateTime.isAfter(endTime5PM) || endDateTime.isBefore(startTime10AM)){ // if both times are before 10 am or both times are after 5 pm (same day)
          burnStartDate = startDateTime;
          burnEndDate = endDateTime;          
        }

        burnListArray.push(
          {...burnListItem,
         
          startDateTime: `${burnStartDate?.seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss')}Z`,
          endDateTime:`${burnEndDate?.seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss')}Z`,
            });
    }
    else{
      let burnDayId = 1;
      const startDayStartTime = startDateTime;
      let nextDayEndTimeISOString ='';
      let nextDayStartTimeISOString = '';
      let exitLoop= false;
      for (let i = 0; i <= dayDifference; i += 1) {
        
        nextDayStartTimeISOString = `${nextDayStartTime.seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss')}Z`;
        nextDayEndTimeISOString = `${nextDayEndTime.seconds(0).milliseconds(0).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss')}Z`;
        if(i === dayDifference){
          exitLoop = true;                  
         
          nextDayStartTimeISOString = nextDayStartTime.isAfter(endDateTime)?`${endDateTime.seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss')}Z`:nextDayStartTimeISOString;
          nextDayEndTimeISOString = endDateTime.isBefore(nextDayEndTime)? `${endDateTime.seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss')}Z` : nextDayEndTimeISOString;
        }

        burnListItem = {
          id:burnDayId.toString(),
          
          startDateTime: nextDayStartTimeISOString,
          endDateTime:nextDayEndTimeISOString,
          description:"",
          area: areaHa === ''? '650' : areaHa,

        }
          burnListArray.push(burnListItem);
        if (exitLoop) break;
        burnDayId +=  1;
        const startTimeCopy = nextDayStartTime;
        const endTimeCopy = nextDayStartTime.clone(); // will be mutated by endof
        nextDayStartTime =startTimeCopy.add(1,'day').startOf('day').add(10,'hour'); // next day 10 am
        nextDayEndTime=endTimeCopy.add(2,'day').startOf('day').subtract(7,'hour'); // next day 5 pm

       
      }
    }
    return burnListArray;
  }
  catch(ex){
    console.log("Error occured while getting burn days ")
    return burnListArray;
  }
}

const highlightSelectedBurnGraphic = (graphic: Graphic) =>{
  view.graphics.removeAll();
  const symbol =  {
    type: "simple-fill",  
    color: "cyan",
    style: "none",
    outline: {  
      color: "cyan",
      width: 2
    }
  };
  const selectedGraphicAttributes = graphic.attributes;
  const  scheduledStartTime10AM = '10:00';
  const scehduledEndTime5PM = '17:00';
  const scheduledBurnStartDate =  moment(selectedGraphicAttributes.scheduledStartDate).add(10,'hour') ;
  const scheduledBurnEndDate =  moment(selectedGraphicAttributes.scheduledEndDate).add(17,'hour');
  const isGraphicSelected = burnGraphicsLayer.graphics.length > 0;
 
  const burnGraphicItem = {
    id: '',
    description:selectedGraphicAttributes.description,
    guarReference:selectedGraphicAttributes.guarReference, 
    scheduledStartDate:selectedGraphicAttributes.scheduledStartDate,
    scheduledEndDate:selectedGraphicAttributes.scheduledEndDate,             
    activityType:selectedGraphicAttributes.activityType,
    burnCoverage:selectedGraphicAttributes.burnCoverage,
    burnIntesity:selectedGraphicAttributes.burnIntesity,
    burnType:selectedGraphicAttributes.burnType,
    leadAgency:selectedGraphicAttributes.leadAgency,
    geometry:selectedGraphicAttributes.geometry,
    burnList:selectedGraphicAttributes.burnList,
    fuelLoad:selectedGraphicAttributes.fuelLoad,
    fuelType:selectedGraphicAttributes.fuelType,
    url:selectedGraphicAttributes.url,
    areaHa:selectedGraphicAttributes.areaHa
};
  dispatch(burnActions.updateIsBurnSelected (isGraphicSelected));  
  dispatch(burnActions.updateSelectedBurnPolygon(burnGraphicItem));

    const polygonGraphic = new Graphic({
      geometry: graphic.geometry,
      symbol
    });
   // view.graphics.add(polygonGraphic);
   burnGraphicsHighlightLayer.graphics.add(polygonGraphic);
}

  const selectFeatures = (geometry:any, event: any) =>  {
    burnGraphicsHighlightLayer.graphics.removeAll();
    view.graphics.removeAll();
    if (guardianBurnPolyLayer) {     
      const query = new Query( {
        geometry,
        where:defQuery, // TODO UNCOMMENT THIS LINE BN
        outFields: ["*"],
        returnGeometry : true,

      });

      const queryTask = new QueryTask({
        url: `${process.env.REACT_APP_BURN_FEATURE_SERVICE_URL!}`
        // url: `${process.env.REACT_APP_BURN_MAPSERVICE_URL!}${process.env.REACT_APP_BURNLAYER_INDEX!}`
      });
      
      queryTask.execute(query).then(function(results){
        const symbol =  {
          type: "simple-fill",  
          color: "cyan",
          style: "none",
          outline: {  
            color: "cyan",
            width: 2
          }
        };
        const resultFeatures = results.features;
        // console.log(resultFeatures);
        const isBurnSelected = resultFeatures.length > 0;
        // Guardian features takes precedence over drawn polygon, if no guardian features intersect, check for drawn polygon
        if(!isBurnSelected){
          view.hitTest(event).then(function (response) {
            const clickResults = response.results;         
            clickResults.forEach(function (result) {
             if (result.graphic.layer === sketchNewBurns.layer) {
                highlightSelectedBurnGraphic(result.graphic);
              }
            });
          });
        }
        dispatch(burnActions.updateIsBurnSelected (isBurnSelected));

        resultFeatures.forEach(function (feat) {          
          const polygonGraphic = new Graphic({
            geometry: feat.geometry,
            symbol
          });
          const selectedBurnAttributes = feat.attributes;
          const scheduledBurnStartDate =  moment(selectedBurnAttributes.ScheduledStartDate).startOf('day').add(10,'hour') ;
          const scheduledBurnEndDate =  moment(selectedBurnAttributes.ScheduledEndDate).startOf('day').add(17,'hour');

          const startDateISO = moment(scheduledBurnStartDate).seconds(0).milliseconds(0).utcOffset(0,true).toISOString(); 
          const endDateISO = moment(scheduledBurnEndDate).seconds(0).milliseconds(0).utcOffset(0,true).toISOString(); 
          const startDateNonISO = moment(scheduledBurnStartDate).seconds(0).milliseconds(0).utcOffset(0,true);
          const endDateNonISO = moment(scheduledBurnEndDate).seconds(0).milliseconds(0).utcOffset(0,true);           
          
          // const startDateISO = moment(scheduledBurnStartDate).seconds(0).milliseconds(0).toISOString(); 
          // const endDateISO = moment(scheduledBurnEndDate).seconds(0).milliseconds(0).toISOString(); 
          // const startDateNonISO = moment(scheduledBurnStartDate).seconds(0).milliseconds(0);
          // const endDateNonISO = moment(scheduledBurnEndDate).seconds(0).milliseconds(0);  

          // const featureAreaHa = geometryEngine.planarArea(feat.geometry as Polygon,'hectares').toFixed(2);
          const featAreaGeodesic = geometryEngine.geodesicArea(feat.geometry as Polygon,'hectares').toFixed(2).toString();
          const burnListArray = getTimeValues(startDateISO,endDateISO,startDateNonISO,endDateNonISO,featAreaGeodesic);

       // convert geometry to wkt string to save in db
         const burnGeometryJSON = feat.geometry.toJSON();
         const burnPolyGeojson = arcgisToGeoJSON(burnGeometryJSON);
         const burnPolywktString = stringify(burnPolyGeojson);
         // console.log('feat Geomertry');
         // console.log(burnGeometryJSON);
        //  console.log('burnGeometryJSON');
        //  console.log(burnPolyGeojson);
        //  console.log('wktstring ');
        //  console.log(burnPolywktString);


        
         
         const burnItem = {
              id: '',
              description:selectedBurnAttributes.Description,
              guarReference:selectedBurnAttributes.GUARReference, 
              scheduledStartDate:`${startDateISO?.split('.')[0]}Z`,
              scheduledEndDate:`${endDateISO?.split('.')[0]}Z`,             
              activityType:selectedBurnAttributes.ActivityType,
              burnCoverage:'50-60',
              burnIntesity:'High',
              burnType:'FUEL REDUCTION',
              leadAgency:selectedBurnAttributes.LeadAgency,
              geometry:burnPolywktString,
              burnList:burnListArray,
              fuelLoad:'27',
              fuelType:'Forest',
              url:selectedBurnAttributes.URL,
              areaHa: featAreaGeodesic
          };
          dispatch(burnActions.updateSelectedBurnPolygon(burnItem));
          dispatch(burnActions.updateIsBurnSelected (isBurnSelected));
          view.graphics.add(polygonGraphic);
        });
      });
    }
  }

  const onGraphicUpdate = (event:any) =>{
    const graphicGeom = event.graphics[0].geometry as Polygon;
    if (
      event.toolEventInfo &&
      (event.toolEventInfo.type === "move-stop") ){
        const graphicsCentroid = graphicGeom.centroid;
        dispatch(burnActions.updateScenarioExtentCentroid({
          latitude:graphicsCentroid.latitude,
          longitude: graphicsCentroid.longitude
        }));

        dispatch(burnActions.updateSelectedScenarioDomain({
          latitude:graphicsCentroid.latitude,
          longitude: graphicsCentroid.longitude
        }));
      }
  }

const setupGraphicClickHandler = (event:any) => {
              // check if the sketch's state active if it is then that means
            // the graphic is already being updated, no action required.
        if (sketchVM.state === "active") {
          return;
        }
        view.hitTest(event).then(function (response) {
          const {results} = response;         
          results.forEach(function (result) {
            if (result.graphic.layer === sketchVM.layer) {
              sketchVM.update([result.graphic], { tool: "move" });
            }            
          });
        });
}

const setBurnGraphicProperties = () =>{ 
  const graphic = updatedBurnGraphic;
  
  if(graphic)
  {
  const graphicGeometry = graphic.geometry;
  const graphicGeometryJSON = graphicGeometry.toJSON();
  const graphicGeometryGeojson = arcgisToGeoJSON(graphicGeometryJSON);
  const graphicGeometrywktString = stringify(graphicGeometryGeojson);

  // const startDateISO = moment(startDate).seconds(0).milliseconds(0).utcOffset(0,false).toISOString(); 
  // const endDateISO = moment(endDate).seconds(0).milliseconds(0).utcOffset(0,false).toISOString(); 
  // const startDateNonISO = moment(startDate).seconds(0).milliseconds(0).utcOffset(0,false);
  // const endDateNonISO = moment(endDate).seconds(0).milliseconds(0).utcOffset(0,false); 

  // const utcStartDate = moment(startDate);
  // const utcEndDate = moment(endDate);

  // const startDateISO = moment(startDate).seconds(0).milliseconds(0).utcOffset(0,false).toISOString(); 
  // const endDateISO = moment(endDate).seconds(0).milliseconds(0).utcOffset(0,false).toISOString(); 
  // const startDateNonISO = moment(startDate).seconds(0).milliseconds(0).utcOffset(0,false);
  // const endDateNonISO = moment(endDate).seconds(0).milliseconds(0).utcOffset(0,false); 

  const startDateISO = moment(startDate).seconds(0).milliseconds(0).toString(); 
  const endDateISO = moment(endDate).seconds(0).milliseconds(0).toString(); 

  // const startDateISO = moment(startDate).seconds(0).milliseconds(0).utcOffset(0,false).toISOString(); 
  // const endDateISO = moment(startDate).seconds(0).milliseconds(0).utcOffset(0,false).toISOString(); 

  const startDateNonISO = moment(startDate).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss');
  const endDateNonISO = moment(endDate).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss');
  
  
  const areaHa = geometryEngine.geodesicArea(graphicGeometry as Polygon,'hectares').toFixed(2).toString();
  
  const burnListArray=  getTimeValuesPolygonGraphic(startDate,endDate,areaHa);
   
   graphic.attributes = {
       // hasAttributes: true,
       description: burnDescription,
       guarReference:'BA-9999999999999',       
      //  scheduledStartDate: `${moment(startDate).seconds(0).milliseconds(0).format('DD MMM YYYY HH:mm:ss')}Z`, // startDate,
      //  scheduledEndDate:`${moment(endDate).seconds(0).milliseconds(0).format('DD MMM YYYY HH:mm:ss')}Z`, // endDate,  
      scheduledStartDate:`${startDateNonISO?.split('.')[0]}Z`,
      scheduledEndDate:`${endDateNonISO?.split('.')[0]}Z`,   
       activityType:'Moderate Intensity Burn',
       burnCoverage,
       burnIntesity:'High',
       burnType,// 'Hazard Reduction',
       leadAgency:'Custom',
       geometry:graphicGeometrywktString,
       burnList:burnListArray,
       fuelLoad,
       fuelType,
       url:'',
       areaHa
 };
  }
 }
 
 const resetBurnAttributes = () =>{
   setBurnDescription(BURN_DESC);
   const burnStartDate = formatTime('');
   const burnEndDate = formatTime('')
   setStartDate(burnStartDate);
   setEndDate(burnEndDate);
   setBurnCoverage(BURN_COVERAGE);
   setBurnType(BURN_TYPE);
   setFuelLoad(FUEL_LOAD);
   setFuelType(FUEL_TYPE);
 }
 
 const setGraphicAttributes = () => {
    const graphic = updatedBurnGraphic;
   const graphicAttributes= graphic?.attributes;
   setBurnDescription(graphicAttributes?.description);
   setStartDate(graphicAttributes?.scheduledStartDate);
   setEndDate(graphicAttributes?.scheduledEndDate);
   setBurnCoverage(graphicAttributes?.burnCoverage);
   setBurnType(graphicAttributes?.burnType);
   setFuelLoad(graphicAttributes?.fuelLoad);
   setFuelType(graphicAttributes?.fuelType);
 }


    const setGraphicGeometry = ()  =>{
      // const graphicAttributes= graphic?.attributes;
      const graphic = updatedBurnGraphic;
      const graphicGeometry = graphic?.geometry;
      const graphicGeometryJSON = graphicGeometry?.toJSON();
      const graphicGeometryGeojson = arcgisToGeoJSON(graphicGeometryJSON);
      const graphicGeometrywktString = stringify(graphicGeometryGeojson);
      // setgraphicGeomWKT(graphicGeometrywktString);
    }

 const handleCancel = () => {
  if(newBurnGraphic){
    burnGraphicsHighlightLayer.graphics.removeAll();
    burnGraphicsLayer.graphics.remove(newBurnGraphic);
  }
  setOpen(false);
};

const handleClose = () => {
  setOpen(false);
};
const handleOk = () =>{
  if(moment(startDate).isAfter(moment(endDate))){
    seterrorTextStartDate('Start date cannot be after end date!!');
    return;
  }

  seterrorTextStartDate('');
  setOpen(false);
   setBurnGraphicProperties();
   // setGraphicGeometry();

  // const updatedBurnGraphicAttributes = updatedBurnGraphic?.attributes;

//   const burnItem = {
//     id: '',
//     description:updatedBurnGraphicAttributes.description,
//     guarReference:updatedBurnGraphicAttributes.guarReference, 
//      /* eslint-disable */
//     scheduledStartDate:updatedBurnGraphicAttributes.scheduledStartDate?.split('.')[0] + 'Z',
//     scheduledEndDate:updatedBurnGraphicAttributes.scheduledEndDate?.split('.')[0] + 'Z',
//      /* eslint-enable */             
//     activityType:updatedBurnGraphicAttributes.activityType,
//     burnCoverage:'50-60',
//     burnIntesity:'High',
//     burnType:'FUEL REDUCTION',
//     leadAgency:updatedBurnGraphicAttributes.leadAgency,
//     geometry:updatedBurnGraphicAttributes.geometry,
//     burnList:updatedBurnGraphicAttributes.burnList,
//     fuelLoad:'27',
//     fuelType:'Forest',
//     url:updatedBurnGraphicAttributes.url,
//     areaHa:updatedBurnGraphicAttributes.areaHa
// };
// if(burnGraphicsLayer.graphics.length > 0){
//   dispatch(burnActions.updateSelectedBurnPolygon(burnItem)); 
//   setburnItemGraphic(burnItem);
// }

}

  useEffect(() => {
    if (mapRef.current && !ready) {
      WatchUtils.whenOnce(view, 'container', () => {
        // console.log('Map is Ready!');
        setReady(true);       
        

        view.when((event:any) => {
          view.ui.add(expand, {
            position: "top-right"
          });

          view.watch("scale", (newValue) => {
          guardianBurnPolyLayer.renderer =
              newValue <= 72224 ? polygonRender : pointRenderer;
          });
    
          
          view.ui.add("select-by-rectangle","top-right");
          view.ui.add("delete-burnPolygon", "top-right");   
          view.ui.add("clear-selection", "top-left");     
         //  console.log(`Map Spatialreference ${JSON.stringify(view.spatialReference)}`);    

          sketchNewBurns.view = view;
          sketchVM.view = view;
          search.view = view;
          expand.view = view;
          const selectButton = document.getElementById("select-by-rectangle");
          // document.getElementById("select-by-rectangle")?.addEventListener("click", () => {  
          //   console.log("select by rectangle clicked.");
          // });

          selectButton?.addEventListener("click", ()=>{
            view.popup.close();
            sketchNewBurns.create("polygon");
          })
         
          document.getElementById("delete-burnPolygon")?.addEventListener("click", () => {  
            removeBurnGraphics();
            dispatch(burnActions.updateIsBurnSelected (false));
          });
          document.getElementById("clear-selection")?.addEventListener("click", () => {  
          removeAllGraphics();
        });

        sketchVM.on("update", onGraphicUpdate);
        
        sketchNewBurns.on("create", async (newBurnevent:any) => {
          // resetBurnAttributes();
          if (newBurnevent.state === "start") {
          burnGraphicsHighlightLayer.graphics.removeAll();
          burnGraphicsLayer.graphics.removeAll();
          }
          if (newBurnevent.state === "complete") {
            setNewBurnGraphic(newBurnevent.graphic);
            setUpdatedBurnGraphic(newBurnevent.graphic);
            resetBurnAttributes();
            setOpen(true);     
          }
        });
        
      });
        view.on("click", function (event) {
          if(sketchNewBurns.state === "active") return;
          const pointGeom = event.mapPoint;

          selectFeatures(pointGeom,event);
          setupGraphicClickHandler(event);
        });

      });

      view.container = mapRef.current;
    }
  }, [mapRef.current]);

  return (<>
  <div className="map" ref={mapRef} />
  <div id="select-by-rectangle" className="esri-widget esri-widget--button esri-widget esri-interactive"
        title="Create burn polygon">
      <span className="esri-icon-checkbox-unchecked"/>
    </div>
    <div id="delete-burnPolygon" className="esri-widget esri-widget--button esri-widget esri-interactive"
        title="Delete burn polygon graphic">
      <span className="esri-icon-trash"/>
    </div>
  <div id="clear-selection" className="esri-widget esri-widget--button esri-widget esri-interactive"
        title="Clear graphics">
      <span className="esri-icon-erase"/>
    </div>
    <Dialog open={open} keepMounted aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Description</DialogTitle>
        <DialogContent>
          <form  noValidate autoComplete="off">
            <div className={classes.root}>    
            <Grid container spacing={2}>   
              <Grid item xs={8}>
                <TextField 
                required 
                fullWidth 
                margin="normal"
                id="descriptionText" 
                label="Description"
                onChange={burnDescriptionChangeHandler} 
                value={burnDescription}            
                defaultValue={BURN_DESC} /> 
                </Grid>  
                <Grid item xs={4}>   </Grid>  
            <Grid item xs={6}>
                <TextField 
                  disabled
                  id="guarRefIdText"
                  label="GUAR Ref ID" 
                  defaultValue='BA-9999999999999'
                   />
            </Grid>                
                 <Grid item xs={6}>
                <FormControl                
                variant ="standard"
                style={{minWidth: 120}}
                >
              <InputLabel id="fuelType">Fuel Type</InputLabel>
              <Select
                labelId="fuelType"
                id="lbFuelType"
                value={fuelType}
                onChange={fuelTypeChangeHandler} 
                label="fuelType"
              >                
                <MenuItem value="Forest">Forest</MenuItem>
                <MenuItem value="Grass">Grass</MenuItem>
              </Select>
              </FormControl>
              </Grid>
              <Grid item xs={6}>
              <TextField              
                  id="datetime-burnStart"
                  label="Start Date"
                  type="datetime-local"
                  value={startDate}    
                  error={!!errorTextStartDate}
                  helperText={errorTextStartDate} 
                  onBlur= {(event)=>{burnStartDateBlurHandler(event)}}
                  onChange = {(event)=>{burnStartDateChangeHandler(event)}}
                  InputProps={{                   
                    style: { width: "220px" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />  
                </Grid>    
                <Grid item xs={6}>
                <TextField 
                 disabled
                id="fuelLoadText"  
                label="Fuel Load" 
                defaultValue="27.2" 
                value ={fuelLoad}
                />    
                </Grid>
               <Grid item xs={6}>
              <TextField           
                  id="datetime-burnEnd"
                  label="End Date"
                  type="datetime-local"                 
                  value={endDate} 
                  onBlur= {(event)=>{burnEndDateBlurHandler(event)}}
                  onChange = {(event)=>{burnEndDateChangeHandler(event)}} 
                  InputProps={{                   
                    style: { width: "220px" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> 
                </Grid>
                <Grid item xs={6}>
                <FormControl                
                variant ="standard"
                style={{minWidth: 120}}
                >
                <InputLabel id="burnType">Burn Type</InputLabel>
              <Select
                labelId="burnType"
                id="slBurnType"
                value={burnType}
                onChange={burnTypeChangeHandler} 
                label="burnType"
              >                
                <MenuItem value="Hazard Reduction">Hazard Reduction</MenuItem>
                <MenuItem value="Wildfire">Wildfire</MenuItem>
              </Select> 
              </FormControl> 
              </Grid> 
            <Grid item xs={4}>
                <TextField 
                disabled
                 id="agencyText" 
                label="Agency" 
                defaultValue='Custom' 
                />
                </Grid>
                <Grid item xs={4}>
                <FormControl                
                variant ="standard"
                style={{minWidth: 120}}
                >
                <InputLabel id="burnCoverage">Burn Coverage</InputLabel>
              <Select
                labelId="burnCoverage"
                id="demo-simple-select-standard"
                value={burnCoverage}
                onChange={burnCoverageChangeHandler} 
                label="Burn Coverage"
              >                
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="20">20</MenuItem>
                <MenuItem value="30">30</MenuItem>
                <MenuItem value="40">40</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="60">60</MenuItem>
                <MenuItem value="70">70</MenuItem>
                <MenuItem value="80">80</MenuItem>
                <MenuItem value="90">90</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select> 
              </FormControl> 
              </Grid> 
                  <Grid item xs={4}>
                                <TextField 
                  disabled 
                  id="burnIntensityText" 
                  label="Burn Intensity" 
                  defaultValue="High" 
                  value = "High" 
                /> 
                </Grid>
              </Grid>             
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleOk} color="primary">
            OK
          </Button>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  </>);
};

export default MapView;
