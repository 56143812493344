import {configureStore} from '@reduxjs/toolkit';
import burnSlice from './burn-slice';


const  store = configureStore({
    reducer: {burn:burnSlice.reducer
            }
}
);

export default store;
export type RootState = ReturnType<typeof store.getState>