import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MapView from 'components/MapView';
import { MapCommand } from 'MapCommands';
import { RootState } from 'store';
import Paper from "@material-ui/core/Paper";
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'

import FullWidthTabs from './FullWidthTabs';
import 'react-reflex/styles.css';





const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    mapPaper:{
      height:'100%',
    },
    control: {
      padding: theme.spacing(1)
    },
    tabsPaper:{
      height:'100%'     
    }
    
  })
);

export default function SpacingGrid() {
  const classes = useStyles();
   const [dispatcher, setDispatch] = useState<{ dispatch: (command: MapCommand) => void }>();

  const registerMapCommand = (mapDispatch: (command: MapCommand) => void) => {
    setDispatch({ dispatch: mapDispatch });
  };
const burnData = useSelector((state: RootState) =>   state.burn.items);
const [burnDataToDisplay, setBurnDataToDisplay]= useState(burnData);

const addNewBurnHandler = (newBurnItem: any) =>{
  setBurnDataToDisplay((preveiousBurnData:any)=>{
    return [newBurnItem,...preveiousBurnData]
  })
}

  return (    
    <ReflexContainer orientation="horizontal">
      <ReflexElement minSize={100} >
        <Paper  className={classes.mapPaper}  >
          <MapView registerMapCommand={registerMapCommand} />
        </Paper>
      </ReflexElement>
      <ReflexSplitter />
      <ReflexElement  minSize={100}>
        <FullWidthTabs mapDispatcher={dispatcher} onAddNewBurn={addNewBurnHandler} />
      </ReflexElement>
    </ReflexContainer>        
  );
}
