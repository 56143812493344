
/* eslint max-classes-per-file: ["error", 10] */
import ESRIMapView from '@arcgis/core/views/MapView';
import Geometry from '@arcgis/core/geometry/Geometry';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import Polygon from '@arcgis/core/geometry/Polygon';
import Extent from '@arcgis/core/geometry/Extent';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
 import * as projection from "@arcgis/core/geometry/projection";
 import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { burnActions } from 'store/burn-slice';
import { useDispatch } from 'react-redux';

export interface MapCommand {
  apply: (view: ESRIMapView, command: MapCommand) => void;
}

export class ZoomTo implements MapCommand {

  constructor(public geometry: Geometry) {}

  apply(view: ESRIMapView) {
    // console.log('ZoomTo was dispatched');
    view.goTo({
      target: this.geometry,
      zoom: 8
    });    
  }
}

export class AddPointGraphic implements MapCommand {
  constructor(public latitude: number, public longitude:number) {}

  apply(view: ESRIMapView) {
    // console.log('addGraphic was dispatched');
    // const pointGeometry = new Point({
    //   latitude: this.latitude,
    //   longitude: this.longitude
    // });
    // console.log(pointGeometry);
    const graphic = new Graphic({
      geometry: new Point({
          latitude: this.latitude,
          longitude: this.longitude,
      }),
      
      symbol: new SimpleMarkerSymbol({
          style: 'square',
          color:  [0, 92, 230,0.5],
          size: '20px',
          outline: {
              color:  'blue',
              width: 1.5,
          },
          angle:45,
      })
  });

// view.graphics.removeAll();
  view.graphics.add(graphic);
    
  }
}

export class CreateBurnLocation implements MapCommand {

  constructor(public burnGeometries: Array<any>, public isTapm:boolean, public isSelectedBurns:boolean) {}

  apply(view: ESRIMapView) {

    
    const graphicLayer:any = view.map.findLayerById("burnPointGraphicsLayer");
    graphicLayer.graphics.removeAll();
    if(!this.isTapm || !this.isSelectedBurns) return;
      
      const symbol =  new SimpleMarkerSymbol({
          style: 'square',
          color:  [0, 92, 230,0.5],
          size: '20px',
          outline: {
              color:  'blue',
              width: 1.5,
          },
          angle:45,
      });

      this.burnGeometries.forEach((burnItem:any) => {
        const burnCentroid = burnItem.centroid;
        const graphic = new Graphic({
          geometry: new Point({
              latitude: burnCentroid.latitude,
              longitude: burnCentroid.longitude,
          }),
          symbol,
    });
  graphicLayer.graphics.add(graphic);
    });

  }
}

export class ZoomToLocation implements MapCommand{

  constructor(public latitude: number, public longitude:number,public isSmall:boolean, public isTapm:boolean, public isSelectedBurns:boolean) {}
 
  apply(view: ESRIMapView) {   

    const graphicLayer:any = view.map.findLayerById("GraphicsLayer");
    view.graphics.removeAll();
    graphicLayer.graphics.removeAll();

    if(!this.isTapm || !this.isSelectedBurns) return; 

  const latM = (1 / 110922);
                        const longM = (1 / 92384.75);
                        let dLat = 0;
                        let dLong = 0;
                        if (this.isSmall) {
                            dLat = latM * 50000;
                            dLong = longM * 50000;
                        } else {
                            dLat = latM * 150000;
                            dLong = longM * 150000;
                        }
                        const xmin = this.longitude - dLong / 2;
                        const xmax = this.longitude + dLong / 2;
                        const ymin = this.latitude - dLat / 2;
                        const ymax = this.latitude + dLat / 2;
                        const polygonExtent = new Extent({xmax,xmin,ymax,ymin});
                        const polygonGeometry = Polygon.fromExtent(polygonExtent);
                        //  new Polygon({rings:[[[xmin, ymin]], [[xmax, ymin]], [[xmax, ymax]], [[xmin, ymax]]]});
                        const simpleFillSymbol = new SimpleFillSymbol ({
                         
                          color:  [255,255,0, 0.5],  // yellow, opacity 50%
                          outline: {
                              color: 'blue',
                              width: 1
                          }
                       });

                      const outSpatialReference = new SpatialReference({
                        wkid: 102100 // Sphere_Sinusoidal projection
                      });
                       const projectedPoly =  projection.project(polygonGeometry, outSpatialReference) as Polygon;
                      
                       const graphic = new Graphic({
                        geometry: projectedPoly,                        
                        symbol: simpleFillSymbol
                    });                  
                  
                    graphicLayer.add(graphic);
                    view.goTo({
                      target: polygonGeometry.extent,
                      zoom: 8
                    }); 
    
  }
}

export class AddBurnPolygonGraphic implements MapCommand{

  // constructor(public geometry: Geometry) {}
  constructor(public geometry: any) {}

  apply(view: ESRIMapView) {
    const simpleFillSymbol = new SimpleFillSymbol ({
      // style:"none",
                         
      color:  [255,255,0, 0.5],  // Orange, opacity 80%
      outline: {
          color: 'yellow',
          width: 2
      }
   });
   const burnPolygon = new Polygon(this.geometry);
   const graphic = new Graphic({
    geometry: burnPolygon,                        
    symbol: simpleFillSymbol
});

 view.graphics.removeAll();

// console.log(burnPolygon);
view.graphics.add(graphic);
view.goTo({
  target: burnPolygon.extent,
  zoom: 15
});  
  }
}

export class AddPolygonGraphic implements MapCommand {
  constructor(public latitude: number, public longitude:number,public isSmall:boolean) {}

  apply(view: ESRIMapView) {
    // console.log('addPolygonGraphic was dispatched');
    // const pointGeometry = new Point({
    //   latitude: this.latitude,
    //   longitude: this.longitude
    // });
    // console.log(pointGeometry);


    view.graphics.removeAll();

  const latM = (1 / 110922);
                        const longM = (1 / 92384.75);
                        let dLat = 0;
                        let dLong = 0;
                        if (this.isSmall) {
                            dLat = latM * 50000;
                            dLong = longM * 50000;
                        } else {
                            dLat = latM * 150000;
                            dLong = longM * 150000;
                        }
                        const xmin = this.longitude - dLong / 2;
                        const xmax = this.longitude + dLong / 2;
                        const ymin = this.latitude - dLat / 2;
                        const ymax = this.latitude + dLat / 2;
                        const polygonExtent = new Extent({xmax,xmin,ymax,ymin});
                        const polygonGeometry = Polygon.fromExtent(polygonExtent);
                        //  new Polygon({rings:[[[xmin, ymin]], [[xmax, ymin]], [[xmax, ymax]], [[xmin, ymax]]]});
                        const simpleFillSymbol = new SimpleFillSymbol ({
                         
                          color:  [255,255,0, 0.5],  // Orange, opacity 80%
                          outline: {
                              color: 'blue',
                              width: 1
                          }
                       });
                       const graphic = new Graphic({
                        geometry: polygonGeometry,                        
                        symbol: simpleFillSymbol
                    });
                  
                  // view.graphics.removeAll();
                  // console.log(graphic);

                    view.graphics.add(graphic);
    
  }
}
