
/* eslint-enable no-param-reassign */

import {createSlice, current} from '@reduxjs/toolkit';
import { BURN_LIST_JSON } from 'data/dummyData';
import { Guid } from 'guid-typescript';
import {burnListItem} from '../models/smokeModel';

// const burnData = JSON.parse(BURN_LIST_JSON);
const selection = {
    id: "",
    description: "Selection Description",
    startDate: '',
    endDate: '',
    particleSize: "PM2.5",
    domain: "Large",
    domainLocation:'' as any,
    weatherSource: "Access",
    burns: [],
    isTapm:true,
    isAqfx:true
}

const burnSlice = createSlice({
    name:'burn',
    initialState:{
    items:[] as any,
    burnItemsAPI:[] as any,
    scenarioBurnItems:[],
    isBurnSelected:false,
    selectedBurnPolygon:{},
    selectedBurnRowIds:[],
    selectedScenarioBurnRowIds:[],
    scenarioItems:[] as  any,
    modelItems:[] as any,
    selectedScenario:selection,
    selectedScenarioId:'',
    scenarioExtentCentroid:''
    },
    reducers:{
        updateAPIBurnItems(state,action){
            state.burnItemsAPI = action.payload;
        },
        addBurnItem(state, action){
            const newBurnItem = {...action.payload};
            newBurnItem.id= Guid.create().toString();            
            state.items.unshift(newBurnItem);
        },
        removeBurnItems(state,action){
            const selectedBurnRowIds = action.payload;
            selectedBurnRowIds.forEach((rowIdItem:any) => {
                state.items= state.items.filter((burnItem:any) => burnItem.id !== rowIdItem)
              });          
        },
        removeBurnItem(state,action){
            const selectedBurnRowId = action.payload;
            state.items= state.items.filter((burnItem:any) => burnItem.id !== selectedBurnRowId);
        },
        removeScenarioBurnItem(state){
            const selectedRowIds = state.selectedScenarioBurnRowIds;
            selectedRowIds.forEach((scenarioRowIdItem:any) => {
                state.scenarioBurnItems= state.scenarioBurnItems.filter((scenarioBurnItem:any) => scenarioBurnItem.id !== scenarioRowIdItem)
              })
            state.selectedScenario.burns = state.scenarioBurnItems;
        },
        removeScenarioBurnItemSingle(state,action){
            const selectedScenarioBurnId = action.payload.burnItemId;           
            state.scenarioBurnItems= state.scenarioBurnItems.filter((scenarioBurnItem:any) => scenarioBurnItem.id !== selectedScenarioBurnId)             
            state.selectedScenario.burns = state.scenarioBurnItems;
        },
        addBurnToSelectedScenario(state,action){
            const newBurnItem = action.payload;
            const selectedScenarioCopy = {...state.selectedScenario}
            const selectedScenarioBurnItemsUpdated = [...state.selectedScenario.burns, newBurnItem];
            selectedScenarioCopy.burns = selectedScenarioBurnItemsUpdated as any;
            state.selectedScenario = selectedScenarioCopy;
            state.scenarioBurnItems = selectedScenarioBurnItemsUpdated as any;
        },
        updateSelectedScenario(state,action){           
            state.selectedScenario = {
                id: action.payload.id,
                description:  action.payload.description,
                startDate: action.payload.startDate,
                endDate:  action.payload.endDate,
                particleSize:  action.payload.particleSize,
                domain:  action.payload.domain,
                domainLocation:  action.payload.domainLocation,
                weatherSource:  action.payload.weatherSource,
                burns:  action.payload.burns,
                isTapm: action.payload.isTapm,
                isAqfx: action.payload.isAqfx
            };
            state.scenarioBurnItems = action.payload.burns;
        },
        updateSelectedScenarioProperty(state,action){            
            state.selectedScenario = {
                        ...state.selectedScenario,
                       ...action.payload
                    }
        },
        updateSelectedScenarioDomain(state,action){  
            const domainObj = {
                latitude: action.payload.latitude,
                longitude:action.payload.longitude
            }
            const selectedScenarioItem = {...state.selectedScenario};
            selectedScenarioItem.domainLocation ={ ...domainObj};
            state.selectedScenario = selectedScenarioItem;
        },
        updateSelectedBurnPolygon(state, action){
            const selectedBurn = action.payload;
            state.selectedBurnPolygon = selectedBurn;
        },
        updateSelectedBurnRowIDs(state, action){
            state.selectedBurnRowIds =action.payload;
        },
        updateSelectedScenarioBurnRowIDs(state, action){
            state.selectedScenarioBurnRowIds =action.payload;
        },
        updateIsBurnSelected(state, action){
            state.isBurnSelected = action.payload;
        },
        updateBurnItems(state, action){
            state.items = action.payload;
        },
        updateScenarioBurnItems(state){
            const burnRowIds = state.selectedBurnRowIds;
            const dbBurnRowIds = state.burnItemsAPI.map((burnItem:any)=> burnItem.id); 
            const filteredRowIdsArray = burnRowIds.filter(value => dbBurnRowIds.includes(value)); // only saved burns should be used for scenario

            state.scenarioBurnItems = state.items.filter((array:any) => filteredRowIdsArray.some(filter => filter === array.id ));
            // state.scenarioBurnItems = state.items.filter((array:any) => burnRowIds.some(filter => filter === array.id ));
            const scenarioItem = {...selection};
            scenarioItem.burns = state.scenarioBurnItems;
            state.selectedScenario = scenarioItem;  
        },
        addScenarioItem(state, action){
            const newScenarioItem = {...action.payload};
            state.scenarioItems.unshift(newScenarioItem);
        },
        updateScenarioItems(state,action){            
            state.scenarioItems = action.payload;
        },
        updateModelItems(state,action){            
            state.modelItems = action.payload;           
        },
        updateSelectedScenarioId(state,action){
            state.selectedScenarioId = action.payload;
        },
        updateBurnDayItem(state, action){
            const burnId = action.payload.id;
            const propertyName = action.payload.property;
            const burnDayIdValue= action.payload.burnDayId;
            const selectedBurn = state.items.find((burnItem:any)=>burnItem.id ===burnId);
            const selectedIndex = state.items.findIndex((burnItem:any)=>burnItem.id ===burnId);
            const selectedBurnListIndex = selectedBurn.burnList.findIndex((burnDayItem:any)=>burnDayItem.id ===burnDayIdValue);
            const updatedBurnDayProperty = action.payload.property;
             const selectedBurnDay = selectedBurn.burnList.find((burnDayItem:any)=>burnDayItem.id ===burnDayIdValue);
             /* eslint-disable */
             selectedBurnDay[propertyName] = action.payload.updatedValue;
              /* eslint-enable */
             state.items[selectedIndex].burnList[selectedBurnListIndex] = selectedBurnDay;
        },
        updateRowItemScheduledStartDate(state,action){
            const burnId = action.payload.id;
            const scheduledStartDate =action.payload.updatedScheduledStartTime;
            // const selectedBurn = state.items.find((burnItem:any)=>burnItem.id ===burnId);
            const selectedIndex = state.items.findIndex((burnItem:any)=>burnItem.id ===burnId);
            state.items[selectedIndex].scheduledStartDate = scheduledStartDate;
        },
        updateRowItemScheduledEndDate(state,action){
            const burnId = action.payload.id;
            const scheduledEndDate =action.payload.updatedScheduledEndTime;
            // const selectedBurn = state.items.find((burnItem:any)=>burnItem.id ===burnId);
            const selectedIndex = state.items.findIndex((burnItem:any)=>burnItem.id ===burnId);
            state.items[selectedIndex].scheduledEndDate = scheduledEndDate;
        },
        addNewBurnDay(state, action){
            const burnId = action.payload.id;
            let nextId = 0;
            const burnStartTime = action.payload.burnRowStartTime;
            const burnEndTime= action.payload.burnRowEndTime;
            const areaH = action.payload.areaHa || '650';
            const selectedIndex = state.items.findIndex((burnItem:any)=>burnItem.id ===burnId);
            if (state.items[selectedIndex].burnList.length === 0){
                nextId = 1
            }
            else{
                const max = Math.max.apply(null, state.items[selectedIndex].burnList.map((item:any) => +item.id));
                nextId = max + 1;
            }
            const burnDayItem = {
                                id: nextId.toString(),
                                area: areaH,
                                description: "",                               
                                startDateTime: burnStartTime,
                                endDateTime: burnEndTime,                                
                            };
                            state.items[selectedIndex].burnList.push(burnDayItem);

        },
        updateScenarioExtentCentroid(state,action){
            state.scenarioExtentCentroid = `${action.payload.latitude.toFixed(4).toString()}, ${action.payload.longitude.toFixed(4).toString()}`
        },

       removeSelectedBurnDay(state, action){
            const burnId = action.payload.id;
            const {burnDayId} = action.payload;
            const selectedIndex = state.items.findIndex((burnItem:any)=>burnItem.id ===burnId);
            const selectedBurnDayIndex =state.items[selectedIndex].burnList.findIndex((burnDayItem:any)=>burnDayItem.id ===burnDayId);

                            state.items[selectedIndex].burnList.splice(selectedBurnDayIndex,1);

        },
    }
});

export default burnSlice;
export const burnActions = burnSlice.actions;
    