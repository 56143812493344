import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import Layout from "./Layout";
import NavBar from "./NavBar";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
    },
  }),
);
const ProtectedApplication = () =>{
    const classes = useStyles();
return (
    <div className={classes.root}>
<NavBar />
<Layout />
</div>
)
}

export default ProtectedApplication;