/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import configData from "config.json";
import FireplaceIcon from '@material-ui/icons/Fireplace';
import CachedIcon from '@material-ui/icons/Cached';
import SaveIcon from '@material-ui/icons/Save';
import { 
  createMuiTheme, 
  createStyles,
  Snackbar,
  TablePagination, 
  Theme, 
  ThemeProvider } from "@material-ui/core";

import SearchBar from "material-ui-search-bar";
// @ts-ignore
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { burnActions } from "store/burn-slice";
import {formatTimeforTableCell as formatTime,formatTimeforTableCellLocal as formatTimeLocal} from '../helper/helperService';
import EditableTableCellText from "./EditableElements";


export const StyledSearchBar = styled(SearchBar)`
  max-width: 475px;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

const tabletheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {  
        padding: '1px 1px 1px 1px',
      },
    },
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    searchbar:{
      width:475,      
      marginBottom: theme.spacing(2)
    },

  })
);


function Row(props: { row: any; setSnackbarOpen: (isOpen:boolean)=>void; setSeverity: (severity:string)=>void; setMessage: (message:string) =>void;}) {  
  const { row, setSnackbarOpen, setSeverity, setMessage } = props;
  const [open, setOpen] = React.useState(false);
  const [analystCommentVal, setAnalystCommentVal] = useState(row.analyst_comment);
  const classes = useRowStyles();
  const burnData =  useSelector((state: RootState) => state.burn.items);
  const modelData = useSelector((state: RootState) =>   state.burn.modelItems);
  const dispatch = useDispatch();

const onViewModelHandler = (rowItem:any)=>{  
  const newPageUrl = `${window.location.origin}/viewer/viewer2D.html?id=${rowItem.id}`; 
   window.open(newPageUrl, "_blank") 
}



async function reloadModels(){
 try{
   const fetchModels = await fetch(process.env.REACT_APP_MODEL_URL!,
   {headers : { 
     'Content-Type': 'application/json',
     'Accept': 'application/json'
    }
   }
   );
   if(fetchModels.ok){
     const refreshedModelData = await fetchModels.json();    
     dispatch(burnActions.updateModelItems(refreshedModelData));
   }
   else{
     console.log("error while fetching models..")
   }
 }
 catch (err){
   console.log("error while fetching models..");
   console.log(err);    }
}

async function saveAnalystComment () {
 try{
     // const formattedSelection = fetchFormattedSelection();
     const selectedModelItem = modelData.find((modelItem:any)=>modelItem.id === row.id);
     
     if(!selectedModelItem || !(analystCommentVal)){        
      setMessage("Analyst comment cant be an empty string!!");
      setSeverity("warning");
      setSnackbarOpen(true);
      
       return;
     } 

     if(analystCommentVal=== row.analyst_comment){
      setMessage("Analyst comment value has'nt changed!!");
      setSeverity("warning");
      setSnackbarOpen(true);      
       return;
     } 

     const modelItemCopy = {
       ...selectedModelItem,
       request_status:"validated",
       analyst_comment: analystCommentVal
    };

    
      const response = await fetch(process.env.REACT_APP_MODEL_URL!,
        {
         method:'PUT',
         body:JSON.stringify(modelItemCopy),
         headers:{
           'Accept': 'application/json',
           'Content-Type':'application/json'
         }
       });

       if (response.ok) {
         setMessage("Successfully updated comment..");
         setSnackbarOpen(true);
         setSeverity("success");
         reloadModels();
       }
       else{
         setMessage("Error updating comment..");
       setSnackbarOpen(true);
       setSeverity("warning");
       }
         }
     catch (err){
       console.log(err.message);
     setMessage("Error updating comment");
     setSnackbarOpen(true);
     setSeverity("error");
   }
}

const onSaveAnalystCommentHandler = ()=>{  
  saveAnalystComment();
}

const handleACBlur = (updatedText:string) =>{
  setAnalystCommentVal(updatedText);
}

  const formattedRequestedTime = formatTime(row.requested_at);
  const formattedStartTime = formatTimeLocal(row.model_start_at);
  const isVMDisabled = configData.MODEL.VIEWABLE_STATUS.indexOf(row.request_status?.toString().toLowerCase())< 0;
  // console.log(row.request_status);
  return (
    <>
      <TableRow
      hover
      // aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      // selected={isItemSelected}
      >     
      <TableCell>
        <IconButton  color="secondary" disabled={isVMDisabled} title="View Model" aria-label="View Model" onClick={() => {onViewModelHandler(row)}}>
          <FireplaceIcon color="primary" />
        </IconButton><IconButton size="small"  color="secondary" title="Save analyst comments" aria-label="View Model" onClick={onSaveAnalystCommentHandler}>
          <SaveIcon color="primary" />
        </IconButton>
        </TableCell>        
        <TableCell component="th" >
        {row.model_name}
        </TableCell>       
        <TableCell align="left">{row.request_status}</TableCell>
        <TableCell align="left">{row.requested_by}</TableCell>
        <TableCell align="left">{formattedRequestedTime}</TableCell>
        <TableCell align="left">{formattedStartTime}</TableCell>   
        <EditableTableCellText onBlur={(event:any)=>{handleACBlur(event)}}>
          <TableCell align="left">{analystCommentVal}</TableCell>
        </EditableTableCellText>    
      </TableRow>
    </>
  );
}

function Alert(props:any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ModelsTable( ) {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [selected, setSelected] = React.useState<string[]>([]);

   const modelData = useSelector((state: RootState) =>   state.burn.modelItems);
  // const selectedScenarioId = useSelector((state: RootState) =>   state.burn.selectedScenarioId);
  const [rows, setRows] = useState<any>(modelData);
  const [searched, setSearched] = useState<string>("");

  // const rows =  modelData ;// [] as any ; // scenarioData;
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const [dense, setDense] = React.useState(false);

  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
 const dispatch = useDispatch();
  useEffect(()=>{
    setRows(modelData);
  },[modelData]);

  const handleSnackbarClose = (event:any, reason:any) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  }; 

  
  const requestSearch = (searchedVal: string) => {
    const filteredRows = modelData.filter((row:any) => {
      return row.model_name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  async function reloadModels(){
    try{
      const fetchModels = await fetch(process.env.REACT_APP_MODEL_URL!,
      {headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
      }
      );
      if(fetchModels.ok){
        const refreshedModelData = await fetchModels.json();    
        dispatch(burnActions.updateModelItems(refreshedModelData));
      }
      else{
        console.log("error while fetching models..")
      }
    }
    catch (err){
      console.log("error while fetching models..");
      console.log(err);    }
   }

  const refreshRecordsHandler = () =>{
    reloadModels();
  }
 // const {onFocusScenariosLayoutTab} = props;
  const rowCount = rows.length;
  const numSelected = selected.length;
  return (
    <div className={classes.root}>
      <Paper>
         <StyledSearchBar  className={classes.searchbar}
          value={searched}
          placeholder='Search models by name'
          onChange={(searchVal:any) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </Paper> 
      {/* <Paper>
      <IconButton size="small"  color="secondary" title="Refresh" aria-label="View Model" onClick={refreshRecordsHandler}>
          <CachedIcon color="primary" />
        </IconButton>
      </Paper> */}
       <Paper className={classes.paper}>
        <ThemeProvider theme={tabletheme}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead classes={classes}>
                <TableRow>
                <TableCell/>
                  <TableCell size="small">Model Name</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Requested by</TableCell>
                  <TableCell align="left">Requested Time</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">Analyst Comments</TableCell>                               
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row:any) => {
                  const isItemSelected = isSelected(row.id);
                  return(
                          // <Row focusScenariosLayout={onFocusScenariosLayoutTab}  key={row.id} row={row}  isChecked={isItemSelected} onRowClicked={onRowClickHandler}/>
                          <Row setSnackbarOpen={setSnackbarOpen} setSeverity={setSeverity} setMessage={setMessage} key={row.id} row={row}  />
                        );
                    })
                }
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </Paper>
       <div className={classes.root}>
      <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>     
    </div>
    </div>
  );
}
