import React, { useEffect, useRef } from 'react';

 const EditableTableCellText = (props:any) => {
    const { onBlur } = props;
    const element = useRef<HTMLInputElement>(null);
    let elements:any = React.Children.toArray(props.children);
    if (elements.length > 1) {
      throw Error("Can't have more than one child");
    }
    const onBlurHandler = () => {
      const value = element.current?.value! || element.current?.innerText;
      onBlur(value);
    };
    useEffect(() => {
      const value = element.current?.value || element.current?.innerText;
      onBlur(value);
    }, []);
    elements = React.cloneElement(elements[0] as any, {
      contentEditable: true,
      suppressContentEditableWarning: true,
      ref: element,
      onBlur: onBlurHandler
    });
    return elements;
 }
 export default EditableTableCellText;
