/* eslint-disable import/first */

import esriConfig from '@arcgis/core/config';
import {Auth0Provider} from '@auth0/auth0-react';

esriConfig.assetsPath = './assets';

import '@arcgis/core/assets/esri/themes/light/main.css';


import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import App from './App';

import reportWebVitals from './reportWebVitals';

// const domain= process.env.REACT_APP_AUTH0_DOMAIN as string;
// const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;

ReactDOM.render(
  <React.StrictMode>
   
     <BrowserRouter>
      {/* <Auth0Provider
    domain ={domain}
    clientId={clientId}
    redirectUri={window.location.origin}

    > */}
     <Provider store = {store}>     
    <App />
    </Provider>
    {/* </Auth0Provider> */}
    </BrowserRouter>
    
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
