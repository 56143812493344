/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { AddBurnPolygonGraphic, CreateBurnLocation, MapCommand,  ZoomToLocation } from 'MapCommands';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import configData from "config.json";
// @ts-ignore
import _ from 'lodash';
import { Checkbox, 
  createMuiTheme,
  createStyles, 
  FormControl, 
  FormControlLabel, 
  FormLabel, 
  Grid, 
  Radio, 
  RadioGroup, 
  Snackbar, 
  Switch, 
  TablePagination, 
  TextField, 
  Theme, 
  ThemeProvider} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { burnActions } from "store/burn-slice";
import SaveIcon from '@material-ui/icons/Save';
import { Guid } from "guid-typescript";
import moment from "moment";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
// @ts-ignore
import { geojsonToArcGIS } from '@esri/arcgis-to-geojson-utils';
import Polygon from "@arcgis/core/geometry/Polygon";
import Geometry from "@arcgis/core/geometry/Geometry";
import {formatTimeforTableCell as formatTime} from '../helper/helperService'
// import Point from "@arcgis/core/geometry/Point";

const wkt = require('wkt');

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },

    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 240,
    },
    scenarioFormRoot:{
      margin: theme.spacing(1),
      width: '25ch',
    }
  })
);

function Row(props: { row: any }) {
  const dispatch = useDispatch(); 
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();  
  const onRemoveBurnItem = (burnItemId:string) => {
    dispatch(burnActions.removeScenarioBurnItemSingle({
      burnItemId    
    }));  
   }
  return (
    <>
   
      <TableRow
      hover           
      tabIndex={-1}
      key={row.id}      
      >
        <TableCell>
            <IconButton color="primary" title="Remove Burn item" onClick={() => {onRemoveBurnItem(row.id)}} aria-label="remove burn item" component="span">
              <DeleteIcon />
            </IconButton>
          </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" >
          {row.description}
        </TableCell>
        <TableCell align="right">{row.guarReference}</TableCell>
        <TableCell align="right">{formatTime(row.scheduledStartDate)}</TableCell>
        <TableCell align="right">{formatTime(row.scheduledEndDate)}</TableCell>
        <TableCell align="right">{row.activityType}</TableCell>
        <TableCell align="right">{row.burnCoverage}</TableCell>
        <TableCell align="right">{row.burnIntesity}</TableCell>
        <TableCell align="right">{row.burnType}</TableCell>
      </TableRow>
      <TableRow
      hover
      // style={{backgroundColor:'blue'}}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Days
              </Typography>
              <Table size="small" aria-label="days">
                <TableHead>
                  <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell align="left">Area</TableCell>                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.burnList.map((burnListRow:any) => (
                    <TableRow key={burnListRow.id}>
                      <TableCell  component="th" scope="row">
                        {formatTime(burnListRow.startDateTime)}
                      </TableCell>
                      <TableCell >{formatTime(burnListRow.endDateTime)}</TableCell>
                      <TableCell >{burnListRow.area}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function Alert(props:any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function ScenariosLayout(props:{onFocusScenariosTab: ()=>void; mapDispatcher:any}) {
  const classes = useStyles();
  const {onFocusScenariosTab, mapDispatcher} = props;
  const selectedScenario = useSelector((state: RootState) =>   state.burn.selectedScenario);
  const dispatch = useDispatch();
  // const [selected, setSelected] = React.useState<string[]>([]);
  const [wsValue, setWsValue] = React.useState(selectedScenario.weatherSource);
  const [psValue, setPsValue] = React.useState(selectedScenario.particleSize);
  const [msValue, setMsValue] = React.useState(selectedScenario.domain);
  const [secenarioDescription, setScenarioDescription] = React.useState(selectedScenario.description);
  const[startDateText, setStartDateText] = useState(selectedScenario.startDate);
  const[endDateText, setEndDateText] = useState(selectedScenario.endDate);

  const formatGivenTimeForDatabase = (timeItem:string) => {
    const updatedStartDateTime = `${moment(timeItem, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString().split('.')[0]}Z`;
    return updatedStartDateTime;
  }
  
  const formatGivenTimeForDTPicker =  (timeItem:string) =>{
    let timeItemValue = timeItem;
        if(timeItemValue === ''){
        timeItemValue = moment(Date.now()).utcOffset(0, true).toISOString();
    }
    const givenDate = moment(timeItemValue).utcOffset(0, false).format("YYYY-MM-DD" ); // .format("DD-MM-YYYY" );
    const givenTime = moment(timeItemValue).utcOffset(0, false).format("HH:mm");
    const formattedTime = `${givenDate}T${givenTime}`
    return formattedTime;
  }

  const formattedSrtDate = formatGivenTimeForDTPicker(startDateText);
  const formattedEdDte = formatGivenTimeForDTPicker(endDateText); 
  const [formattedStartDate, setFormattedStartDate] = useState(formattedSrtDate);
  const [formattedEndDate, setFormattedEndDate] = useState(formattedEdDte);
 
 // const scenarioData = useSelector((state: RootState) =>   state.burn.scenarioBurnItems);
  const updatedScenarioCentroid = useSelector((state: RootState) => state.burn.scenarioExtentCentroid);
  const rows =  selectedScenario.burns;

  // const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [dense, setDense] = React.useState(false);
  const descTextRef = useRef<any>(null);
  // const selectedScenarioBurnIds =  useSelector((state: RootState) => state.burn.selectedScenarioBurnRowIds);
  const tabletheme = createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {  
          padding: '1px 1px 1px 1px',
        },
      },
    }
  });

  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [domainText,setDomainText] = useState('');

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  type ScenarioBurnListItem ={
    id:string
  }
  let scenarioBurnList = Array<ScenarioBurnListItem>();
  let  scenarioItem = {
    id:'',
    description:'',    
    startDate:'',
    endDate:'',
    particleSize:'',
    domain:'',
    domainLocation:'',
    weatherSource:'', 
    burns:scenarioBurnList,
    isTapm:true,
    isAqfx:true   
    };

  const [state, setState] = React.useState({
    isAqfx: selectedScenario.isAqfx,
    isTapm: selectedScenario.isTapm 
  });
  
  const scenarioTypeCheckboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    dispatch(burnActions.updateSelectedScenarioProperty({
      [event.target.name]: event.target.checked 
    }));
  };

  const wsChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWsValue((event.target as HTMLInputElement).value);
    dispatch(burnActions.updateSelectedScenarioProperty({
      weatherSource: (event.target as HTMLInputElement).value
    }));
  };
  
  const psChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPsValue((event.target as HTMLInputElement).value);
    dispatch(burnActions.updateSelectedScenarioProperty({
      particleSize: (event.target as HTMLInputElement).value
    }));
  };
  
  const msChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMsValue((event.target as HTMLInputElement).value);
    dispatch(burnActions.updateSelectedScenarioProperty({
      domain: (event.target as HTMLInputElement).value
    }));
  }; 

  const removeBurnItemHandler = ()=>{
    dispatch(burnActions.removeScenarioBurnItem());
  }

  async function saveScenario (scenarioItemObj:any,newScenarioItem:any){
  
    try{      
        const response = await fetch(process.env.REACT_APP_SCENARIO_URL!,
        {
          method:'POST',
          body:JSON.stringify(scenarioItemObj),
          headers:{
          'Accept': 'application/json',
          'Content-Type':'application/json'
          }
        });

        if (response.ok) {
          setMessage("Successfully saved selection..");
          setSnackbarOpen(true);
          setSeverity("success");         
          const selectedScenarioObj = {
            ...selectedScenario,
            id:newScenarioItem.id
          };
          
         // update selectedScenario as the current one (if new. id is assigned only when it is saved)
        dispatch(burnActions.updateSelectedScenario(selectedScenarioObj));
        
        const getUpdatedScenarios = await fetch(process.env.REACT_APP_SCENARIO_URL!,
          {
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
          }
        );
        const newScenarioData = await getUpdatedScenarios.json();
        dispatch(burnActions.updateScenarioItems(newScenarioData));

        if(getUpdatedScenarios.ok){
          setMessage("Successfully loaded selection(s)..");
          setSnackbarOpen(true);
          setSeverity("success");
        }
      } else {
        setMessage("Error saving selection..");
        setSnackbarOpen(true);
        setSeverity("warning");
      }
      // response.json().then(data => console.log(data));
      setTimeout( () => {onFocusScenariosTab()}, 3000);
    }
    catch (err){
      setMessage("Error saving data");
      setSnackbarOpen(true);
      setSeverity("error");
    }
  }

  const saveScenarioHandler = () =>{
    scenarioBurnList =[];
    selectedScenario.burns.forEach(function (burnItem:any) {
      scenarioBurnList.push(
        {
          id:burnItem.id
        }
      )
    }); 
    let scenarioId = selectedScenario.id;
    if(scenarioId ===''){
      scenarioId = Guid.create().toString();
    }
    const domainLoc = {...selectedScenario.domainLocation};
     scenarioItem = {
            id:scenarioId,
            description: secenarioDescription,    
            startDate: formatGivenTimeForDatabase(formattedStartDate),
            endDate: formatGivenTimeForDatabase(formattedEndDate),
            particleSize:psValue,
            domain:msValue,
            domainLocation:domainLoc,
            weatherSource:wsValue, 
            burns:scenarioBurnList,
            isTapm:state.isTapm,
            isAqfx:state.isAqfx   
    };
    dispatch(burnActions.addScenarioItem(scenarioItem));
    
    const scenarios = [] as any;
    scenarios.push(scenarioItem);
    saveScenario(scenarios,scenarioItem);
  }
     
  const getBurnsCentroid =  () => {
    const scenarioBurns = [...selectedScenario.burns];
    let hasSelectedScenarioBurns = true;
     if (scenarioBurns.length <= 0){ 
        setDomainText('');
        hasSelectedScenarioBurns = false;

        if (props.mapDispatcher) {
            props.mapDispatcher.dispatch(
              new CreateBurnLocation([],false,hasSelectedScenarioBurns)
            );
            props.mapDispatcher.dispatch(
              new ZoomToLocation(
              0,
              0,msValue==="Small",
              false,
              hasSelectedScenarioBurns
            ),
            );    
        } 
      return;
    }

    
    const scenarioBurnsGeomCollection = scenarioBurns.map((burnItem:any):Geometry=>{
      const geometryVal = burnItem.geometry;
      const wktGeom = wkt.parse(geometryVal);
      const esriGeom = geojsonToArcGIS(wktGeom);
      
      // when converting back to esri polygon, somehow spatailReference is changed to 4826, so setting it back to 102100
      const polygon = new Polygon({  
        rings: esriGeom.rings,
        spatialReference: {
          wkid: 102100 
        }
      });
      return polygon;
    });
    const burnPolygonsCollection =  geometryEngine.union(scenarioBurnsGeomCollection);
    const {isTapm} = state;
    const burnPolygon = new Polygon(burnPolygonsCollection);
    // setDomainText (`${burnPolygon.centroid.latitude.toFixed(4).toString()}, ${burnPolygon.centroid.longitude.toFixed(4).toString()}`);
    dispatch(burnActions.updateScenarioExtentCentroid({
      latitude:burnPolygon.centroid.latitude,
      longitude: burnPolygon.centroid.longitude
    }));
    setDomainText(updatedScenarioCentroid);
    if (props.mapDispatcher) {
      props.mapDispatcher.dispatch(
        new CreateBurnLocation(scenarioBurnsGeomCollection,isTapm,hasSelectedScenarioBurns)
      );
      props.mapDispatcher.dispatch(
        new ZoomToLocation(
          burnPolygon.centroid.latitude,
          burnPolygon.centroid.longitude,msValue==="Small",
          isTapm,
          hasSelectedScenarioBurns
        ),
      );
    
     } 
  }

  const drawSelectedScenarioGraphics =  () => {
    const scenarioBurns = [...selectedScenario.burns];
    let hasSelectedScenarioBurns = true;
     if (scenarioBurns.length <= 0){ 
        setDomainText('');
        hasSelectedScenarioBurns = false;

        if (props.mapDispatcher) {
            props.mapDispatcher.dispatch(
              new CreateBurnLocation([],false,hasSelectedScenarioBurns)
            );
            props.mapDispatcher.dispatch(
              new ZoomToLocation(
              0,
              0,msValue==="Small",
              false,
              hasSelectedScenarioBurns
            ),
            );    
        } 
      return;
    }
    const scenarioBurnsGeomCollection = scenarioBurns.map((burnItem:any):Geometry=>{
      const geometryVal = burnItem.geometry;
      const wktGeom = wkt.parse(geometryVal);
      const esriGeom = geojsonToArcGIS(wktGeom);
      
      // when converting back to esri polygon, somehow spatailReference is changed to 4826, so setting it back to 102100
      const polygon = new Polygon({  
        rings: esriGeom.rings,
        spatialReference: {
          wkid: 102100 
        }
      });
      return polygon;
    });
    const burnPolygonsCollection =  geometryEngine.union(scenarioBurnsGeomCollection);
    const {isTapm} = state;
    const burnPolygon = new Polygon(burnPolygonsCollection);
    const burnPolygonCentroidObj = {
      latitude:burnPolygon.centroid.latitude,
      longitude: burnPolygon.centroid.longitude
    }
    // setDomainText (`${burnPolygon.centroid.latitude.toFixed(4).toString()}, ${burnPolygon.centroid.longitude.toFixed(4).toString()}`);
    
    let scenarioCentroidLocation  = burnPolygonCentroidObj;
    const scenarioDomainLocation= selectedScenario.domainLocation as any;
   if(scenarioDomainLocation !== '' && scenarioDomainLocation !== null) // build scenario when domain location is null
    {
      scenarioCentroidLocation ={...scenarioDomainLocation};
    }
     
    dispatch(burnActions.updateScenarioExtentCentroid(scenarioCentroidLocation));
    dispatch(burnActions.updateSelectedScenarioDomain(scenarioCentroidLocation));
  
    setDomainText(updatedScenarioCentroid);
    if (props.mapDispatcher) {
      props.mapDispatcher.dispatch(
        new CreateBurnLocation(scenarioBurnsGeomCollection,isTapm,hasSelectedScenarioBurns)
      );
      props.mapDispatcher.dispatch(
        new ZoomToLocation(
          scenarioCentroidLocation?.latitude,
          scenarioCentroidLocation?.longitude,msValue==="Small",
          isTapm,
          hasSelectedScenarioBurns
        ),
      );
    
     } 
      
  }
  const updateDomainText = ()=>{
    setDomainText(updatedScenarioCentroid)
  } 

   // useEffect(getBurnsCentroid,[selectedScenario.burns, setDomainText,state.checkedTAPM,msValue]);
   useEffect(drawSelectedScenarioGraphics,[selectedScenario.burns, setDomainText,state.isTapm,msValue]);
   useEffect(updateDomainText,[updatedScenarioCentroid,state.isTapm]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  const handleSnackbarClose = (event:any, reason:any) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };


  const scenarioSDescBlurHandler = (event:any) =>{
    setScenarioDescription(event.target.value);
    if(event.target.value !== selectedScenario.description){
    dispatch(burnActions.updateSelectedScenarioProperty({
      description:event.target.value
    }));
  }
  }

  const scenarioStartDateBlurHandler = (event:any) => {
    const updatedStartDateTime = `${moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString().split('.')[0]}Z`;
    setStartDateText(updatedStartDateTime);
    const formattedTime = formatGivenTimeForDTPicker(updatedStartDateTime);
    setFormattedStartDate(formattedTime);
    if(updatedStartDateTime !== selectedScenario.startDate){
      dispatch(burnActions.updateSelectedScenarioProperty({
        startDate:updatedStartDateTime
      }));
  }
}

  const scenarioEndDateBlurHandler = (event:any) =>{
    const updatedEndTime = `${moment(event.target.value, "YYYY-MM-DD HH:mm").utcOffset(0, true).toISOString().split('.')[0]}Z`;
    setEndDateText(updatedEndTime);
    const formattedTime = formatGivenTimeForDTPicker(updatedEndTime);
    setFormattedEndDate(formattedTime);
    if(updatedEndTime !== selectedScenario.startDate){
      dispatch(burnActions.updateSelectedScenarioProperty({
        endDate:updatedEndTime
      }));
  }
  }

  const rowCount = rows.length;
  return (
    <div className={classes.root}>
      <Paper>     
      <IconButton disabled={selectedScenario.burns.length<=0} title="Save Selection" aria-label="Save Selection" onClick={saveScenarioHandler}>
        <SaveIcon color="primary" />
      </IconButton>     
      </Paper>
      <Paper className={classes.paper}>
        <div  >
        <FormControl className={classes.container}>
          <Grid container >
            <Grid item xs={2}>
              <Grid item>
                <TextField 
                inputRef={descTextRef} 
                margin="normal" 
                required 
                id="scenarioDescText" 
                label="Description" 
                onBlur={scenarioSDescBlurHandler}
                defaultValue={secenarioDescription} />      
              </Grid>
              <Grid item>             
                <TextField              
                  id="datetime-scenarioStart"
                  label="Start Date"
                  type="datetime-local"
                  defaultValue={formattedStartDate}
                  onBlur={scenarioStartDateBlurHandler}
                  className={classes.textField} 
                  InputLabelProps={{
                    shrink: true,
                  }}
                />      
              </Grid>
              <Grid item>
                <TextField           
                  id="datetime-scenarioEnd"
                  label="End Date"
                  type="datetime-local"
                  defaultValue={formattedEndDate}
                  onBlur={scenarioEndDateBlurHandler}
                  className={classes.textField}       
                  InputLabelProps={{
                    shrink: true,
                  }}
                />             
              </Grid>
            </Grid>
            <Grid item xs={2}>
            <Grid item>
            <FormLabel component="legend">Type</FormLabel>
            <FormControlLabel
              control={
                <Checkbox  
                  checked={state.isTapm}          
                  onChange={scenarioTypeCheckboxChangeHandler}
                  name="isTapm"
                  color="primary"
                  size="small"
                />
              }
              label="TAPM"
              
            />
            <FormControlLabel
              control={ 
                <Checkbox
                checked={state.isAqfx}  
                  onChange={scenarioTypeCheckboxChangeHandler}
                  name="isAqfx"
                  color="primary"
                  size="small"
                />
              }
              label="AQFX"
            />
          </Grid>

          {(state.isTapm) &&  (
            <Grid item>
            <FormLabel component="legend">Domain: </FormLabel>  
            <FormLabel component="legend">{domainText}</FormLabel>
          </Grid>)}
          </Grid>
          <Grid item  >
           <>
            <Grid item>
              <FormLabel component="legend">Particle size</FormLabel>
              <RadioGroup  row  aria-label="particleSize" name="particleSize" value={psValue} onChange={psChangeHandler}>
              <FormControlLabel    value="PM2.5" control={<Radio  size="small" />} label="PM 2.5" />
              <FormControlLabel   value="PM10" control={<Radio  size="small"/>} label="PM 10" />        
              </RadioGroup>
            </Grid>
            <Grid item>
              <FormLabel component="legend">Model Size</FormLabel>
              <RadioGroup   row  aria-label="modelSize" name="modelSize" value={msValue} onChange={msChangeHandler}>
                <FormControlLabel   value="Small" control={<Radio  size="small"/>} label="Small" />
                <FormControlLabel    value="Large" control={<Radio  size="small"/>} label="Large" />                      
              </RadioGroup>
            </Grid>              
            <Grid item>
              <FormLabel  component="legend">Weather Source</FormLabel>
              <RadioGroup  row  aria-label="weatherSource" name="weatherSource" value={wsValue} onChange={wsChangeHandler}>
              <FormControlLabel    value="Access" control={<Radio  size="small"/>} label="Access" />
              <FormControlLabel    value="GFS" control={<Radio  size="small"/>} label="GFS" />        
              </RadioGroup>
            </Grid>
            </>            
          </Grid>
          </Grid>
        </FormControl>
        </div>
      </Paper>
      <Paper className={classes.paper}>
      <ThemeProvider theme={tabletheme}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead classes={classes}>
            <TableRow>            
              <TableCell />
              <TableCell />
              <TableCell>Description</TableCell>
              <TableCell align="right">Guraf Ref</TableCell>
              <TableCell align="right">Start Date</TableCell>
              <TableCell align="right">End Date</TableCell>
              <TableCell align="right">Activity Type</TableCell>
              <TableCell align="right">Burn Coverage</TableCell>
              <TableCell align="right">Burn Intensity</TableCell>
              <TableCell align="right">Burn Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row:any) => {
                return(                
                  <Row key={row.id} row={row}  />
                  );
              })
             }
            {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
    <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </Paper>
    <div className={classes.root}>
      <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
     
    </div>
  </div>
  );
}
