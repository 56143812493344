/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useAuth0,withAuthenticationRequired,Auth0Provider  } from '@auth0/auth0-react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { MapCommand } from 'MapCommands';
import { useDispatch } from 'react-redux';
import { burnActions } from 'store/burn-slice';
import configData from "config.json";
import { Route, Router, Switch } from 'react-router-dom';
import ProtectedApplication from 'components/ProtectedApplication';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
    },
  }),
);

const domain= process.env.REACT_APP_AUTH0_DOMAIN as string;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const scope = 'openid profile'

export function Loading() {
  return (
    <div className="text-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export const ProtectedRoute = ({
  component,
  ...args
}: React.PropsWithChildren<any>) => (
  <Route
    render={(props) => {
      const Component = withAuthenticationRequired(component, {
        // If using a Hash Router, you need to pass the hash fragment as `returnTo`
        // returnTo: () => window.location.hash.substr(1),
      });
      return <Component {...props} />;
    }}
    {...args}
  />
);

function App() {
  const { isLoading, error } = useAuth0();
  const {
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const classes = useStyles();
  const [dispatcher, setDispatch] = useState<{ dispatch: (command: MapCommand) => void }>();

  const registerMapCommand = (mapDispatch: (command: MapCommand) => void) => {
    setDispatch({ dispatch: mapDispatch });
  };
  const dispatch = useDispatch();

 async function fetchBurnData () {
  try{
    const getUpdatedBurns = await fetch(process.env.REACT_APP_BURN_URL!,
    {headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  );
  const newBurnData = await getUpdatedBurns.json();
  dispatch(burnActions.updateBurnItems(newBurnData));
  dispatch(burnActions.updateAPIBurnItems(newBurnData));
  }
  catch (err){
    console.log("error while fetching burns - App");
    console.log(err);
  }
}

async function fetchScenarioData () {
  try{
    const getUpdatdScenarios = await fetch(process.env.REACT_APP_SCENARIO_URL!,
    {headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  );
  const refreshedScenarioData = await getUpdatdScenarios.json();
  
  dispatch(burnActions.updateScenarioItems(refreshedScenarioData));
  }
  catch (err){
    console.log("error while fetching scenarios - App");
    console.log(err);
  }
}

async function fetchModelData () {
  try{
    const getUpdatdScenarios = await fetch(process.env.REACT_APP_MODEL_URL!,
    {headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  );
  const refreshedModelData = await getUpdatdScenarios.json();
  dispatch(burnActions.updateModelItems(refreshedModelData));
  }
  catch (err){
    console.log("error while fetching models - App");
    console.log(err);
  }
}

useEffect(()=>{
  fetchBurnData();
  fetchScenarioData();
  fetchModelData();
  },[]);

  return (
    <Auth0Provider
    domain ={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    >     
     <Switch>      
      <Route path='/' exact>       
      <ProtectedRoute path="/" component={ProtectedApplication} />
      </Route>
      </Switch>   
      </Auth0Provider>
  );
}

export default App;
